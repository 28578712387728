import axios from '../../plugins/axios';

export const listServices = async ({ commit }) => {
  return axios.get('/schedule/service/')
    .then(res => {
      const data = res.data.results;
      
      commit('SET_SERVICES', data)
      return res
    })
    .catch(err => err.response)
};

export const updateFocus = ({ commit }, newFocus) => {
  commit('SET_FOCUS', newFocus);
};

export const createAppointment = async (_, data = {}) => {
  return await axios.post('schedule/appointment/', data)
    .then(res => ({ data: res.data, response: res }))
    .catch(error => ({ error }))
};

export const updateCurrentAppointment = ({ commit }, newAppointment) => {
  commit('SET_APPOINTMENT', newAppointment);
};

export const rescheduleAppointment = async (_, data = {}) => {
  return await axios.post(data.url_reschedule, data.newDate)
    .then(res => ({ data: res.data, response: res }))
    .catch(error => ({ error }))
};

export const getTimeBlocksByService = async (_, { serviceId, date}) => {
  return await axios.get(`schedule/service/${serviceId}/time_blocks/${date}/`)
    .then(response => ({ data: response.data, response }))
    .catch(error => ({ error }))
}



