<template lang="pug">
.background-budgets(v-if="OT.length > 0")
  v-row.my-0
    //- v-col(cols="12" md="9")
    //-   h2 Presupuestos
    v-col.slider-buttons-desktop(cols="12" md="3")
      v-btn.slider-button(x-small rounded elevation="0" @click="paginate('prev')" v-show="count > 0 && page > 1") &lt; Volver
      v-btn.slider-button(x-small rounded elevation="0" @click="paginate('next')" v-show="page < count") OT anterior &gt;
  v-row.my-0
    v-col(v-for="estimate in OT[0].estimates_data" :key="estimate.id" cols="12" md="6")
      v-card
        v-chip.flex-grow-0.flex-shrink-1.mt-3.ml-3(label :text-color="getStatusTextColor(estimate.state)" :color="getStatusColor(estimate.state)") {{ estimate.state }}
        p.grey--text.ml-3.mt-3.mb-0 {{ formatDate(OT[0].created_at) }}
        p.primary--text.ml-3.mt-2(v-if="estimate.name !== '' && estimate.name !== null") Presupuesto N° {{ estimate.id }} - #[strong {{ estimate.name }}]
        p.primary--text.ml-3.mt-2(v-else) Presupuesto N° {{ estimate.id }}
        v-expansion-panels(accordion flat)
          v-expansion-panel
            v-expansion-panel-header
              v-row
                v-col.d-flex.justify-space-between
                  h3.primary--text {{ estimate.total_price | currency }}
            v-expansion-panel-content(id="detail-table")
              v-card(elevation="0" outlined)
                v-row
                  v-col(cols="12")
                    h3.ma-3 Mano de Obra
                    v-data-table(:headers="tableBudget.headers" :items="estimate.services" hide-default-footer mobile-breakpoint="0" no-data-text="No hay servicios")
                      template(v-slot:item.name="{ item }")
                        p {{ item.name }}
                      template(v-slot:item.quantity="{ item }")
                        p x{{ item.quantity }}
                      template(v-slot:item.total_price="{ item }")
                        p {{ item.total_price | currency }}
                  v-col(cols="12")
                    div.d-flex.justify-end.align-end.mr-5
                      strong Subtotal: {{ estimate.total_services | currency }}
                v-divider
                v-row
                  v-col(cols="12")
                    h3.ma-3 Repuestos
                    v-data-table(:headers="tableSaleItems.headers" :items="estimate.pieces" hide-default-footer mobile-breakpoint="0" no-data-text="No hay repuestos")
                      template(v-slot:item.name="{ item }")
                        p {{ item.name }}
                      template(v-slot:item.quantity="{ item }")
                        p x{{ item.quantity }}
                      template(v-slot:item.total_price="{ item }")
                        p {{ item.total_price | currency }}
                  v-col(cols="12")
                    div.d-flex.justify-end.align-end.mr-5
                      strong.mb-3 Subtotal: {{ estimate.total_pieces | currency }}
                v-divider
                v-row
                  v-col(cols="12")
                    div.d-flex.justify-end.align-end.mr-5.my-2.flex-column
                      p(v-if="estimate.discount > 0") Descuentos: -{{ estimate.discount | currency }}
                      strong Total Presupuesto: {{ estimate.total_price | currency }}
</template>
<script>
import otStatusColors from '../../shared/otStatusColors.json'
export default {
  props: {
    OT: {
      type: Array
    },
    count: {
      type: Number
    },
    page: {
      type: Number
    }
  },
  data () {
    return {
      tableBudget: {
        headers: [
          { text: 'Trabajo', value: 'name', width: '150px' },
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center' },
          { text: 'Precio', value: 'total_price', width: '100px' }
        ]
      },
      tableSaleItems: {
        headers: [
          { text: 'Repuesto', value: 'name', width: '150px' },
          { text: 'Cantidad', value: 'quantity', width: '100px', align: 'center' },
          { text: 'Precio', value: 'total_price', width: '100px' }
        ]
      }
    }
  },
  methods: {
    getStatusTextColor (state) {
      return otStatusColors[state] ? otStatusColors[state].textColor : otStatusColors.default.textColor
    },
    getStatusColor (state) {
      return otStatusColors[state] ? otStatusColors[state].color : otStatusColors.default.color
    },
    formatDate (date) {
      return new Intl.DateTimeFormat('cl-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(date))
    },
    paginate (index) {
      this.$emit('getNext', index)
    }
  }
}
</script>
<style lang="scss" scoped>
.background-budgets {
  background-color: #407BFF;
  /* border-radius: 30px 30px 0px 0px; */
  /* height: 100%; */
  // overflow-x: hidden;
  h2 {
    color: #FFF;
    padding: 20px 0px 10px 30px;
  }
  .slider-buttons-desktop {
    display: none;
  }
  .v-card {
    border-radius: 10px;
    margin: 0 2px;
  }
  @media (max-width: 2560px) and (min-width: 769px) {
    border-radius: 0;
    padding: 0 50px;

    h2 {
      padding-left: 10px;
      margin-bottom: 10px;
      font-size: x-large;
    }

    .slider-buttons-desktop {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;

      .slider-button {
        background-color: #FFB573 !important;
        color: #934500 !important;
      }
    }
  }
}

</style>
<style lang="css">
#detail-table > .v-expansion-panel-content__wrap {
  padding: 0px 0px 0px !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th  {
  border-bottom: none !important;
}

.v-data-table__wrapper {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

</style>
