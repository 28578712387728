<template lang="pug">
.background-budgets2
  v-container(class="pt-6")
    v-tabs(background-color="#407BFF" grow dark v-model="currentTab")
      v-tab.tab-style(key="1") Presupuestos
      v-tab.agenda-style.tab-style(key="2") Agenda
    v-tabs-items(v-model="currentTab")
      v-tab-item(key="1")
        BudgetList(:OT="ot" :count="count" :page="page" @getNext="getNextPage")
      v-tab-item.agenda-style(key="2")
        ScheduleView
</template>
<script>
import BudgetList from '@/components/OTDetail/BudgetList.vue';
import ScheduleView from '@/views/ScheduleView.vue';
import { mapActions } from 'vuex'
export default {
  components: {
    BudgetList,
    ScheduleView,
  },

  data () {
    return {
      currentTab: 1,
      ot: [],
      page: 1,
      count: 0,
      queryParams: null,
      
    }
  },

  methods: {
    ...mapActions('ot', ['getOTData']),

    async fetchData () {
      let rut;
      let patent;
      if(this.queryParams?.login) {
        rut = this.queryParams.rut
        patent = this.queryParams.patent
        localStorage.setItem('rut', JSON.stringify(rut))
        localStorage.setItem('patente', JSON.stringify(patent))
      } else{
        rut = JSON.parse(localStorage.getItem('rut'))
        patent = JSON.parse(localStorage.getItem('patente'))
        if (!rut && !patent) {
          rut = JSON.parse(localStorage.getItem('customer')).rut
          patent = JSON.parse(localStorage.getItem('vehicle')).patent
        }
      }
      await this.getOTData({ params: { page: this.page, rut: rut, patente: patent?.toUpperCase() } }).then(
        response => {
          if (response.status >= 200 && response.status < 300) {
            this.ot = response.data.orders
            this.count = response.data.count
          }
        }
      )
    },
    getNextPage (data) {
      if (data === 'prev') {
        this.page = this.page > 1 ? this.page - 1 : 1
      } else {
        this.page = this.page + 1
      }
      this.fetchData()
      this.$emit('getNext', data)
    }
  },

  mounted() { 
    this.queryParams = this.$route.query
    if(!this.$route.query.login) {
      this.fetchData()
    }
  },

  watch: {
    queryParams () {
      if(this.queryParams.login) {
        this.fetchData()
      }
    },
  },
}
</script>


<style lang="scss" scoped>
.background-budgets2 {
  background-color: #407BFF;
  border-radius: 30px 30px 0px 0px;
  height: 100%;

  h2 {
    color: #FFF;
    padding: 20px 0px 10px 30px;
  }
  .slider-buttons-desktop {
    display: none;
  }
  .v-card {
    border-radius: 10px;
    margin: 0.5rem 22px;
  }
  @media (max-width: 2560px) and (min-width: 769px) {
    border-radius: 0;
    padding: 0 50px;

    h2 {
      padding-left: 10px;
      margin-bottom: 10px;
      font-size: x-large;
    }

    .slider-buttons-desktop {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;

      .slider-button {
        background-color: #FFB573 !important;
        color: #934500 !important;
      }
    }
    
    .agenda-style {
      text-align: -webkit-right;
    }
  }
}

</style>


<style>
/* Helper classes */
.basil {
  background-color: #FFFBE6 !important;
}
.basil--text {
  color: #356859 !important;
}

.tab-style {
  text-transform: capitalize;
}
</style>