import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const ScheduleStore = {
  namespaced: true,
  state: {
    services: [],
    focusDay: '',
    currentAppointment: null,
  },
  getters,
  mutations,
  actions
}

export default ScheduleStore
