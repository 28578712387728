import axios from '../../plugins/axios'

export const getOTData = ({ commit }, { params } = {}) => {
  return axios.get('inventory/customer_data', { params }).then(
    response => {
      commit('SET_OT', response.data.orders)
      commit('SET_CUSTOMERDATA', response.data)
      return response
    }
  )
    .catch(err => err.response)
}